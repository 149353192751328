<template>
  <v-list nav>
    <v-list-item-group>
      <v-list-item :to="{ name: 'Home' }">
        <v-list-item-action>
          <v-icon>home</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("pages.home") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="vueEventos" :to="{ name: 'Eventos' }">
        <v-list-item-action>
          <v-icon>date_range</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("global.evento", 2) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="vueAtendimentos"
        :to="{ name: 'PainelMeusAtendimentos' }"
      >
        <v-list-item-action>
          <v-icon>build</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{
            $tc("pages.atendimento", 2)
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="vueEstoque && modulo_estoque"
        :to="{ name: 'Produtos' }"
      >
        <v-list-item-action>
          <v-icon>list_alt</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("pages.estoque") }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="vueClientes" :to="{ name: 'Clientes' }">
        <v-list-item-action>
          <v-icon>assignment_ind</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ $tc("pages.cliente", 2) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="vueRelatorios" :to="{ path: '/relatorios' }">
        <v-list-item-action>
          <v-icon>description</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Relatorios</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="vueNotificarClientes"
        :to="{ name: 'NotificarClientes' }"
      >
        <v-list-item-action>
          <v-icon>sms</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Notificar Clientes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "MenuDashboard",
  computed: {
    ...mapState("Tocsconfig", {
      modulo_estoque: (state) => state.modulo_estoque,
    }),
    ...mapGetters("Componentes", ["getAccess"]),

    vueAtendimentos() {
      return this.getAccess("vueAtendimentos", "menu");
    },
    vueClientes() {
      return this.getAccess("vueClientes", "menu");
    },
    vueEventos() {
      return this.getAccess("vueEventos", "menu");
    },
    vueEstoque() {
      return this.getAccess("vueEstoque", "menu");
    },
    vueRelatorios() {
      return this.getAccess("vueRelatorios", "menu");
    },
    vueNotificarClientes() {
      return this.getAccess("vueNotificarClientes", "menu");
    },
  },
};
</script>

<style></style>
